<template lang="pug">
div(v-if='isWidgetLoaded')
  lazy-hydrate(when-idle)
    dope-component(:items='widgets')
</template>

<script>
import LazyHydrate from 'vue-lazy-hydration';
import DopeComponent from './components/DopeComponent.vue';

import './styles/app-mobile.slim.scss';

export default {
  name: 'HomepageMobileDopeSection',
  components: {
    DopeComponent,
    LazyHydrate,
  },
  computed: {
    isWidgetLoaded() {
      return this.$store.state.dope.isWidgetLoaded
    },
    widgets() {
      return this.$store.state.dope.widgets
    }
  },
  mounted() {
    this.fetchWidget();
  },
  methods: {
    async fetchWidget() {
      if (!this.isWidgetLoaded) {
        this.$store.dispatch('dope/fetchMenu');
      }
    },
  },
};
</script>
